import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, Redirect, useParams} from 'react-router-dom';

import { Modal } from 'react-responsive-modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { regform } from "../../actions/api-wisdom";
import { transList } from "../../actions/api-wisdom";

import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import 'react-responsive-modal/styles.css';


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const KaivalyaReg = (props) => {
    const { isLoggedIn } = useSelector(state => state.authReducer);
    const { id } = useParams();
    const form = useRef();
    const checkBtn = useRef();
    const [open, setOpen] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [dvijadate, setDvijaDate] = useState(new Date());
    const [dvijaplusdate, setDvijaPlusDate] = useState(new Date());
    const [trans, setTrans] = useState([]);
    const [lang, setLang] = useState(0);
    const [accept, setAccept] = useState(false);
    const [acceptmsg, setAcceptmsg] = useState(false);
    const reg_id = id;
    const regstate = useSelector(state => state),
    { wisdomReducer } = regstate,
    { trans_lists } = wisdomReducer;
    const { message } = useSelector(state => state.messageReducer);

    const initialState = {
        reg_id: reg_id,
		emergency_contact1: "",
		emergency_relation1: "",
		emergency_contact2: "",
        emergency_relation2: "",
        reason_attend: "",
        psychological_problem: "No",
        desc_psychological_problem: "",
        similar_program: "No",
        desc_similar_program: "",
        accomodation: "",
        dvija_date: "",
        dvijaplus_date: "",
        accept_terms: "",
	};

    const [datas, setDatas] = useState(initialState);
    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "Kaivalya Register | Matangi";
        dispatch(transList(4));
    }, [dispatch]);

    useEffect(() => {
        setTrans(trans_lists.data);
	}, [trans_lists]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatas({ ...datas, [name]: value });
    };
    
    const setDateDvija = (date) => {
        setDatas({ ...datas, ['dvija_date']: date });
        setDvijaDate(date);
    };
    const setDateDvijaPlus = (date) => {
        setDatas({ ...datas, ['dvijaplus_date']: date });
        setDvijaPlusDate(date);
    };

    const handleAccept = (event) => {
        const checked = event.target.checked;
        if (checked) {
            setAccept(true);
        } else {
            setAccept(false);
        }
    };

    const handleReg = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            if(!accept){
                setAcceptmsg(true)
            }else{
                dispatch(regform(datas))
                .then(() => {
                setSuccessful(true);
                })
                .catch(() => {
                setSuccessful(false);
                });
            }
        }
    };

    const changeLang = (event) => {
        setLang(event.target.value); 
    };

    const transWord = (title) => {
        if(trans){
            const valune = trans.find(element => element.trans_word===title);
            if(valune && Object.keys(valune).length != 0){
                if(lang ==0){
                    return valune && valune.trans_en;
                }else{
                    return valune.trans_ta;
                }
            }
        }
    }
    if(!isLoggedIn) {
        return <Redirect to="/login" />;
    }

    const onOpenModal = (e) => {
        setOpen(true);
    };
    const onCloseModal = () => setOpen(false);
  return (
    <section className="section login-sec">
			<div className="container">
				<div className="row">
                    <div className="col-md-8">
                    {!successful && (
						<div className="login-right">
							<select name="event_id" className="form-control lang"  value={ lang }  onChange={changeLang}>
                                <option value="0">English</option>
                                <option value="1">Tamil</option>
                            </select>
							<h3>Kaivalya</h3>
							<p>Please Register to continue</p>

							<Form onSubmit={handleReg} ref={form}>
                                <div className="form-group">
                                    <label> { transWord('kaivalya.emergency_contact1') } <sup>*</sup></label>
                                    <Input type="text" className="form-control" name="emergency_contact1" value={ datas.emergency_contact1 } onChange={handleInputChange} validations={[required]} placeholder={ transWord('kaivalya.emergency_contact1') } />
                                </div>
                                <div className="form-group">
                                    <label>{ transWord('kaivalya.emergency_relation1') } <sup>*</sup></label>
                                    <Input type="text" className="form-control" name="emergency_relation1" value={datas.emergency_relation1} onChange={handleInputChange}  validations={[required]} placeholder={ transWord('kaivalya.emergency_relation1') } />
                                </div>
                                <div className="form-group">
                                    <label> { transWord('kaivalya.emergency_contact2') } <sup>*</sup></label>
                                    <Input type="text" className="form-control" name="emergency_contact2" value={datas.emergency_contact2} onChange={handleInputChange} validations={[required]} placeholder={ transWord('kaivalya.emergency_contact2') } />
                                </div>
                                <div className="form-group">
                                    <label> { transWord('kaivalya.emergency_relation2') } <sup>*</sup></label>
                                    <Input type="text" className="form-control" name="emergency_relation2" value={datas.emergency_relation2} onChange={handleInputChange} validations={[required]} placeholder={ transWord('kaivalya.emergency_relation2') } />
                                </div>
                                
                                <div className="form-group">
                                    <label> { transWord('kaivalya.reason_attend') } <sup>*</sup></label>
                                    <Input type="text" className="form-control" name="reason_attend" value={datas.reason_attend} onChange={handleInputChange} validations={[required]} placeholder={ transWord('kaivalya.reason_attend') } />
                                </div>

                                {/* ---------- */}

                                <div className="form-group">
                                    <label> { transWord('kaivalya.psychological_problem') }</label>
                                    <Select name='psychological_problem' className="form-control" value={ datas.psychological_problem } onChange={handleInputChange}>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </Select>
                                </div>
                                <div className={datas.psychological_problem =="Yes" ? "form-group d-block" : "form-group d-none"}>
                                    <label> { transWord('kaivalya.desc_psychological_problem') }</label>
                                    <Input type="text" className="form-control" name="desc_psychological_problem" value={datas.desc_psychological_problem} onChange={handleInputChange}  placeholder={ transWord('kaivalya.desc_psychological_problem') } />
                                </div>
                                {/* ---------- */}

                                <div className="form-group">
                                    <label> { transWord('kaivalya.similar_program') }</label>
                                    <Select name='similar_program' className="form-control" value={ datas.similar_program } onChange={handleInputChange}>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </Select>
                                </div>
                                <div className={datas.similar_program =="Yes" ? "form-group d-block" : "form-group d-none"}>
                                    <label> { transWord('kaivalya.desc_similar_program') }</label>
                                    <Input type="text" className="form-control" name="desc_similar_program" value={datas.desc_similar_program} onChange={handleInputChange} placeholder={ transWord('kaivalya.desc_similar_program') } />
                                </div>
                                
                                {/* ---------- */}
                                
                                <div className="form-group">
                                    <label> { transWord('kaivalya.accomodation') }</label>
                                    <Select name='accomodation' className="form-control" value={ datas.accomodation } onChange={handleInputChange}>
                                        <option value="">Select Option</option>
                                        <option value="Kaivalya Advance">Kaivalya Advance</option>
                                        <option value="Male With Dormitory">Male With Dormitory</option>
                                        <option value="Male with Dormitory And Travel">Male with Dormitory And Travel</option>
                                    </Select>
                                </div>
                                
                                <div className="form-group">
                                    <label> { transWord('kaivalya.dvija_date') }</label>
                                    {/* <DatePicker maxDate={new Date()} selected={ dvijadate } onChange={(date) => setDateDvija(date)} className="form-control" dateFormat="dd/MM/yyyy" placeholderText='Dvija Date'/> */}
                                    <DatePickerInput
                                        maxDate={new Date()}
                                        className='my-react-datepicker'
                                        value={dvijadate}
                                        showOnInputClick
                                        displayFormat='DD/MM/YYYY'
                                        returnFormat='YYYY-MM-DD'
                                        placeholder='Dvija Date'
                                        onChange={(jsDate) => setDateDvija(jsDate)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label> { transWord('kaivalya.dvijaplus_date') }</label>
                                    {/* <DatePicker maxDate={new Date()} selected={ dvijaplusdate } onChange={(date) => setDateDvijaPlus(date)} className="form-control" dateFormat="dd/MM/yyyy" placeholderText='Dvija plus Date'/> */}
                                    <DatePickerInput
                                        maxDate={new Date()}
                                        className='my-react-datepicker'
                                        value={dvijaplusdate}
                                        showOnInputClick
                                        displayFormat='DD/MM/YYYY'
                                        returnFormat='YYYY-MM-DD'
                                        placeholder='Divija Date'
                                        onChange={(jsDate) => setDateDvijaPlus(jsDate)}
                                    />
                                </div>

                                <div className="form-group form-check">
                                    <input type="checkbox" className="form-control display-inline" value={accept}  onChange={handleAccept}/> I accept <a onClick={onOpenModal}> Terms and Conditions</a>
                                    {acceptmsg && (
                                    <div className="alert alert-danger" role="alert">Please accept Terms and Conditions</div>
                                    )}
                                </div>
								<button className="btn btn-primary loginbtn">Submit</button>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                            
                            {message && (
                                <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                                </div>
                            )}
                            <div className="newuser-sec">
                                <p><Link to="/current-event">Click here to View Event Detail</Link></p>
                                <p>If you are facing any issues, Please call us @ <b>0422 4040422</b></p>
							</div>
						</div>
                        )}

                        {successful &&(
                          <div className="login-right">
                          <h4>Registration Updated Successfully</h4>
                          <p><Link to={"/profile"}>Back to Home</Link></p>
                          </div>
                        )}
					</div>

				</div>
			</div>
            <Modal open={open} onClose={onCloseModal} center>
        <div className='player-wrapper'>
            <h4>Terms and Conditions</h4>
            <hr />
            <h4>Agreement</h4>
            <p> I acknowledge that Matangi Foundation will provide programs requiring both physical and mental exertion on my behalf. I will at all times act within my own level of comfort, taking particular care of my injury or complaint that I carry when participating. I acknowledge that this program contains both physical and dynamic practice that requires both mental and physical focus in order to avoid potential injury, and that injury and soreness are a real and material risk in every program. I will always keep Matangi Foundation informed of my comfort levels and will immediately cease any activity if I become uncomfortable with my condition. I acknowledge that Matangi Foundation is providing guidance based on the experience and education of its teachers, and no way do they claim to be medical practitioners, it is my responsibility to decide, to follow or not to follow that advice and guidance given by Matangi Foundation, and in no way do I hold Matangi Foundation responsible for any injury or soreness acquired while undertaking the practices taught in the program, I hereby commit that the information that I have provided is accurate and fully reveals the nature of my state of health as on date of this document. In addition, I undertake to continuously inform the Matangi Foundation of any changes to my health status whilst attending the program. I acknowledge that the donation paid to Matangi Foundation is non-refundable and non-transferable.</p>
            <p>I, Agree to the statements above and I am fully responsible for any consequences and willing to compensate for any loss or damage caused to the organization.</p>
            <h4>Privacy Policy</h4>
            <p>This platform is owned and operated by Dakshina Foundation. In this Privacy Policy, “we”, “us” and “our” refer to Dakshina Foundation. Dakshina Foundation is a non-profit organization, initiated with a divine mission of bringing quality difference to the smothering lifestyle of people who are caught up in an intricate web of the fast track modern era. Dakshina Foundation strives with unstinted focus to facilitate people to unwind and cultivate their hidden potential, channelize their thoughts and actions towards enhancing their productivity in all respective spheres.</p>
            <p>The information provided by (<b>“Matangi Foundation Platform”</b>) on https://matangifoundation.org/ website or mobile application is for general informational purposes only. We recognize that you may be concerned about the information we collect from you through our website and how we treat that information. The Dakshina Foundation values, respects and protects your privacy and therefore we have taken necessary precautions to be in compliance with the provisions of Information Technology Act, 2000. This Privacy Policy enlists the types of information that is collected and recorded by Matangi Foundation platform, and how we use it.</p>
            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in https://matangifoundation.org/. This policy is not applicable to any information collected offline or via channels other than this website.</p>
            <h4>Collection of Personal Data (“Personal Data”)</h4>
            <p>Your privacy and security are our priority, and we strive to incorporate the best security practices in our operations. </p>
            <p>If you are a donor, when you make an online donation on the Matangi Foundation Platform, we collect Personal Data such as your name, billing address, Permanent Account Number, telephone number, email address and any other information provided by you. </p>
            <p>The Personal Data that you are asked to provide, and the reasons for asking the same, shall be made clear to you when you are requested to provide your Personal Data. We only collect information that we believe to be relevant and for the purpose of acknowledging receipt of the donation. </p>
            <p>If you contact us directly, we may ask you to provide additional information about you such as your name, email address, phone number, and the contents of the message and/or attachments you may send us, and any other information you choose to provide. Further, when you register for a program on Matangi Foundation Platform, we may ask for your contact information, including, but not limited to, name, occupation, gender, date of birth, address, email address, medical history and phone number.</p>
            <p>Matangi Foundation Platform will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data shall take place to any other person or organization. Your Personal Data will not be shared with or sold to any third-party companies or organizations.</p>
            <h4>Collection of Non-Personal Data</h4>
            <p>We gather statistical and other analytical information collected on an aggregate basis of all visitors on Matangi Foundation Platform. This Non-Personal Data comprises information that cannot be used to identify or contact you, such as demographic information regarding, for example, clipped or anonymized user IP addresses, browser types and other anonymous statistical data involving the use of Matangi Foundation Platform to understand geography of our visitors and help us better organize the website.</p>
            <h4>How we use your information</h4>
            <p>We will process any Personal Data you provide to us for the following purposes:</p>
            <ul style={{"listStyle":'none'}}>
                <li>1.	Provide, operate, and maintain our website;</li>
                <li>2.	Improve, personalize, and expand our website;</li>
                <li>3.	Understand and analyze how you use our website;</li>
                <li>4.	Develop new products, services, features, and functionality;</li>
                <li>5.	Communicate with you, either directly or through one of our partners, to provide you with updates and other information relating to the website, or for marketing and promotional purposes;</li>
                <li>6.	Send you emails;</li>
                <li>7.	Find and prevent fraud;</li>
                <li>8.	To respond to any customer service-related queries; </li>
                <li>9.	Creating profiles for donees;</li>
            </ul>

            <p>We also use this information to send you an 80G certificate (if applicable) upon your request and to communicate with you in case of any trouble in processing the donation.</p>
            <p>If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please email us at info@matangifoundation.org</p>
            <h4>Cookies</h4>
            <p>When you visit the Matangi Foundation Platform, we send one or more cookies – a small file containing a string of characters – to your computer or other device that uniquely identifies your browser. We use cookies to improve the quality of our service, including for storing user preferences, improving search results and ad selection, and tracking user trends. Matangi Foundation Platform also uses cookies in its advertising services to help advertisers and publishers serve and manage ads across the web. We may set one or more cookies in your browser when you visit a website. Cookies can make the site more useful by storing information about your preferences on particular sites, thus enabling website owners to provide more useful features for their users. The information collected by "cookies" is anonymous aggregated research data and contain no name or address information or any information that will enable anyone to contact you via telephone, e-mail or any other means. Most browsers are initially set to accept cookies. If you would prefer, you can set your browser to disable cookies or inform you when they are set. However, by disabling them, you may not be able to take full advantage of our website. This Privacy Policy does not cover cookies as used by the web- advertising partners. You are always free to decline such cookies, if your browser permits, or to ask your browser to indicate when a cookie is being sent.</p>
            <p>Disclosure of Personal Data</p>
            <p>We shall not disclose your Personal Data to third parties unless you have consented to this disclosure. We shall disclose your Personal Data if we believe in good faith that we are required to disclose it in order: (a) to comply with any applicable law, a summons, a search warrant, a court or regulatory order or other statutory or legal requirement; (b) to enforce the Matangi Foundation Terms & Conditions or to protect our rights; or (c) to protect the safety of users of the Matangi Foundation Platform.</p>
            <p>We will share limited Personal Data of the users as required with our agents, representatives, service providers and contractors in order to offer you services in connection with our website, communicate news and information about programs, provide customer service, enhance and improve user experiences, and complete financial transactions. These service providers are authorized to use your information only to the extent necessary to serve these purposes, or as otherwise authorized by this policy. We also share your Personal Data when you make a donation or when you donate to a project, we share your name and contact information and the amount of your donation with the relevant non-profit organisation. If Matangi Foundation merges with, or becomes an affiliate of another organization, we will ensure the confidentiality of any Personal Data involved in such transactions and provide notice before Personal Data is transferred and becomes subject to a different privacy policy.</p>
            <h4>Retention</h4>
            <p>We will retain your information for as long as needed to provide our services. We may also retain and use your Personal Data in order to comply with our contractual or legal obligations, audit requirements, resolve disputes, prevent abuse and other statutory/legal purposes. For example, tax laws in India may require us to keep contact information and contribution level of donors on a file.</p>
            <p>The information we collect is only for the improvement of our services. We may retain your information to provide certain services to users, to recognize them the next time they use Matangi Foundation Platform or to send promotional emails and SMS.</p>
            <h4>Amendments to this Policy</h4>
            <p>If we decide to change our privacy policy, we will post those changes to this privacy statement so you are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We will use information in accordance with the privacy policy under which the information was collected. We encourage you to periodically review this policy for the latest information on our privacy practices.</p>
            <h4>Rights</h4>
            <p>You have certain rights with respect to the Personal Data we collect about you. Upon your request, we tell you what information we hold about you, we rectify any incomplete or inaccurate information and also restrict the use of your information. We will make reasonable efforts to delete your information if you ask us to do so, unless we are otherwise required to keep it.</p>
            <h4>Links to other websites</h4>
            <p>The Matangi Foundation Platform contains links to other websites including our partners. Dakshina Foundation is not responsible for the privacy policy or practices on such linked sites. This Privacy Policy governs only information collected on this website. When linking to another website, you should read the privacy policy stated on that website. Please be aware that the terms of our Privacy Policy do not apply to these outside websites. Our site contains links to other sites and resources provided by third parties for your convenience and information only. We accept no liability in connection with any third-party website accessed via a link from the Matangi Foundation Platform, or any contract entered into or through a third-party website. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them.</p>
            <h4>Copyright Protection</h4>
            <p>All content on this Matangi Foundation Platform including graphics, text, icons, interfaces, audio clips, logos, images and software is the property of Dakshina Foundation and/or its content suppliers and is protected by Indian and international copyright laws. The arrangement and compilation (meaning the collection, arrangement, and assembly) of all content on this Matangi Foundation Platform is the exclusive property of Dakshina Foundation and protected by Indian and international copyright laws. Permission is given to use the resources of this Matangi Foundation Platform only for the purposes of making enquiries, making a donation or placing an order for the purchase of products. Any other use, including the reproduction, modification, distribution, transmission, republication, display or performance, of the content on this Matangi Foundation Platform can only be made with the express permission of Dakshina Foundation. All other trademarks, brands and copyrights other than those belonging to Dakshina Foundation belong to their respective owners and are their property.</p>
            <h4>Security</h4>
            <p>We understand the importance of keeping your information secure and confidential. To safeguard your information, we have implemented effective policies for this purpose. When you make a donation, information about your payment card is transmitted securely through a Payment Gateway. Payment Gateways allow you to make a payment electronically using your credit card, debit card, net banking or other payment methods.</p>
            <p>The payment gateways collect data that may include your contact details, your card details and the transaction details which facilitates your online payment. We do not collect or store any of these personal details and we do not have any control over them. This data is completely managed by the Payment Gateway tools according to their own privacy policies.</p>
            <p>We have taken and will continue to take reasonable efforts to protect Personal Data in our possession or control by making reasonable security arrangements to prevent unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks.</p>
            <p>While we strive to protect your Personal Data, we cannot ensure the security of any Personal Data which you may have transmitted to us, via the Internet, and we urge you to take every precaution to protect your Personal Data and use a secure browser.</p>
            <h4>Consent</h4>
            <p>By using our website “https://matangifoundation.org/”, you hereby consent to our Privacy Policy and agree to its terms.</p>
            <h4>Contact Us</h4>
            <p>If you have any comments or questions about the collection, use or disclosure of your Personal Data or this Privacy Policy Statement, please contact us in writing at the address below referencing ‘Privacy Policy Statement’:</p>
            <br/>
            <p>Data Protection Officer <br/>Email: info@matangifoundation.org<br/>Telephone no: 0422 404 0422</p>
            
            <h4>Refund And Cancellation Policy</h4>
            <hr />
            <p>Dakshina Foundation is instituting a refund and cancellation policy for the donors who have given donations to the Dakshina Foundation or have opted for any services, projects, programs for which a receipt for tax exemption has been issued.</p>
            <h4>Tax Exemption</h4>
            <p>All the payments paid to Dakshina Foundation Trust are eligible for an exemption (i.e. tax benefit) of 50%, under the section 80G(5)(vi) of Income Tax Act 1961 and are considered as donations. While opting for any service that Dakshina Foundation provides, under different projects or for different programs, a receipt for tax exemption will be issued to the donor.</p>
            <h4>Refund Policy</h4>
            <p>Dakshina Foundation is not obliged to make refunds and shall decline any requests for refund of donations, for which a tax exemption certificate has been issued. Any refund/cancellation for the donated amount by any donor will not be entertained, whether online, electronically or through any other means.</p>
            <p>No cash or refund of money shall be allowed. Once the donation is received for a cause, the same will not be refunded to the donor. No cancellations are allowed for any services, projects, programs or any other purpose for which the donation has been provided.</p>
            <p>Dakshina Foundation expects that all donors will exercise due care and diligence while making donations. Any request for cancellations and refund donations once duly placed, shall not be entertained under any circumstances.</p>
            <p>Dakshina Foundation</p>
            <p>Email: info@matangifoundation.org</p>
            <p>All decisions of Dakshina Foundation in this regard will be final and binding on the donor.</p>
        </div>
        </Modal>
		</section>
  );
};

export default KaivalyaReg;
