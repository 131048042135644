import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash';
import { logout } from "../actions/api-auth";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { paymentList, messageUpdate } from "../actions/api-home";

import jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

import { config } from '../actions/config.js';
import authHeader from "../actions/auth-header";
import axios from 'axios';
const BASEURL = config.BASEURL;

const PaymentList = (props) => {
  const { isLoggedIn } = useSelector(state => state.authReducer);
  const [paymentlist, setPaymentList] = useState([]);
  const [search, setSearch] = useState('');
  const [htmlpdf, setHtmlpdf] = useState();
  const { message } = useSelector(state => state.messageReducer);
  const currentstate = useSelector(state => state),
  { homeReducer } = currentstate,
  { payment_lists } = homeReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Profile | Donation List";
		dispatch(paymentList(search));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(payment_lists.data)) {
			setPaymentList(payment_lists.data);
		}
	}, [payment_lists]);

  if(!isLoggedIn) {
    localStorage.setItem("navtype", 1);
    return <Redirect to="/login" />;
  }
  const userflag = localStorage.getItem("profile_status");
  if(userflag ==1){
    alert("Please update Your Profile Details");
    props.history.push("/profile");
    window.location.reload();
  }
  const logOut = (e) => {
    dispatch(logout());
  };

    const handleSearchChange = (event) => {
      setSearch(event.target.value);
    };

    const handleSearch = (event) => {
      dispatch(paymentList(search));
    };

    const downloadpdf = async(e) => {
      const data = {'invoice_id' : e.target.dataset.id, 'invoice_type' : 'mandir'};
      const res = await axios.post(BASEURL + 'register/pdf', data, { headers: authHeader() });
      if(res.data){
        setHtmlpdf(res.data);
        const input = document.getElementById('pagepdf');
        html2canvas(input,{scale: 2})
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          const imgProps= pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save('invoice.pdf');
          setHtmlpdf('');
        });
      }
    };

    return (
    <section className="section profile-sec">
			<div className="container-fluid">
				<div className="row">
          <div className="col-md-3">
						<div className="profile-left">
              <div className="list-group list-group-flush">
                  <Link to="/profile" className="list-group-item list-group-item-action"><span>Profile <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/current-event" className="list-group-item list-group-item-action"><span>Current Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/history-event" className="list-group-item list-group-item-action"><span>History Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/donation-list" className="list-group-item list-group-item-action active"><span>Donation List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/favourite-list" className="list-group-item list-group-item-action"><span>Favourite List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/login" onClick={logOut} className="list-group-item list-group-item-action"><span>Logout <i className="fa fa-chevron-right"></i></span></Link>
              </div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="profile-right">
              <div className="profile-head">
							  <h3>Donation List</h3>
              </div>
							<p></p>

        <div className="row">
          <div className="col-md-12">
            <div className="profiletabs">
              <ul className="nav nav-tabs" role="tablist">
                <li className="active">
                  <a href="#tab4" role="tab" data-toggle="tab">Success Payments</a>
                </li>
                <li>
                  <Link to="/donation-pending" role="tab" data-toggle="tab">Pending Payments</Link>
                </li>
              </ul>

                <div className="tab-content top-color-border">
                <div className="tab-pane fade in active" id="tab4">
                  <div className="row">
                  <div className="form-group col-md-4">
              <input type="text" className="form-control" name="mobile" value={ search } onChange={handleSearchChange} placeholder="Search..."  style={{'height':"35px"}}/>
              </div>
              <div className="form-group col-md-4">
              <button className="btn btn-success history-btn btn-sm" onClick={handleSearch}>Search</button>
              </div>
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Payment Type</th>
                    <th>Amount</th>
                    <th>Payment Status</th>
                    <th>Payment ID</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                    { paymentlist.length > 0 ? paymentlist.map((paymentdata, pindex) => (
                    <tr>
                      <td>{pindex +1}</td>
                      <td>{paymentdata.payment_type}</td>
                      <td>{paymentdata.currency} {paymentdata.amount}</td>
                      <td><button className="btn btn-sm history-btn btn-success">{paymentdata.pay_status}</button>{paymentdata.pay_status =='success' && ( <button className="btn btn-sm history-btn btn-success" onClick={downloadpdf} data-id={paymentdata.id}>Download Invoice</button>)}</td>
                      <td> {paymentdata.payment_id}</td>
                      <td>{paymentdata.created_date}</td>
                    </tr>
                    )) : (
                      <tr>
                        <td>No records found</td>
                      </tr>
                      )
                    }
                </tbody>
              </table>
                  </div>
                </div>
                <div id="pagepdf" dangerouslySetInnerHTML={{__html: htmlpdf }}></div>
              </div>
            </div>
          </div>
          </div>




              

						</div>
					</div>
				</div>
			</div>
      
		</section>
  );
};

export default PaymentList;
