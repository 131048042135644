import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { isEmpty } from 'lodash';
import { resultDecrypt } from "../actions/api-wisdom";

const Paymentstatus = () => { 
  const { id } = useParams();
  const resultstate = useSelector(state => state),
  { wisdomReducer } = resultstate,
  { result_data } = wisdomReducer;

  const dispatch = useDispatch();
  const [resultData,setResultData]=useState([]);
  useEffect(() => {
    document.title = "Programs | Matangi";
    localStorage.removeItem("navtype");
    localStorage.removeItem("event_id");
    dispatch(resultDecrypt(id));
  }, [dispatch,id]);

  useEffect(() => {
    setResultData(result_data);
  }, [result_data]);

  return (
    <div>
    <section className="section login-sec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
                <div className="pay-right">
                    {resultData.info_success ===1 && (
                    <div>
                        {resultData.gateway_status =='1' ? (
                        <div>
                          {resultData.payment_status =='success' && (<h3>Success</h3>)}
                          {resultData.payment_status =='failed' && (<h3>Failed</h3>)}
                          {resultData.payment_status =='pending' && (<h3>Pending</h3>)}
                          <ul>
                              <li><strong>Transaction ID:</strong> { resultData.tracking_id }</li>
                              <li><strong>Total:</strong> { resultData.currency } { resultData.amount }</li>
                              <li><strong>Message:</strong> { resultData.status_message }</li>
                          </ul>

                          {resultData.pay_from =='mandir' ? (
                            <p><Link to={"/donation-list"}>Click here to View Info</Link></p>
                          ) : (
                            <div>
                              {resultData.payment_status =='success' && (
                              <p>Your registration number is {resultData.regid}. Further details will be sent to you shortly.</p>)}
                            </div>
                          )}
                        </div>
                        ):
                        (
                          <div>
                            <h3>Failed</h3>
                            <ul>
                                <li><strong>Message:</strong> { resultData.status_message }</li>
                            </ul>
                          </div>
                        )}
                    </div>
                    )}
                    {resultData.info_success ===0 && (
                    <div>
                        <h3>Failed</h3>
                        <ul>
                            <li><strong>Message:</strong> { resultData.status_message }</li>
                        </ul>
                        <p><Link to={"/profile"}>Click here to View Info</Link></p>
                    </div>
                    )}
                </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default Paymentstatus;
